import {
    gql
} from '@apollo/client'

const GET_CUSTOMER_PROFILE_BY_ID_QUERY = gql`
    query customerProfile($customerId: String){
        customerProfile (customerId: $customerId) {
            __typename
            id
            avatar
            cover_image
            image_bot
            firstname
            lastname
            authentication_status
            account_type
            description
            phone_number
            secondary_phone_number
            email
            secondary_mail
            location
            account_status
            is_salon_owner
            country_code
            phone_country_code
            setup_password_yet
            addresses {
                id
                street
                city
                region {
                    region
                    region_code
                    region_id
                }
                postcode
                country_id
                country_code
            }
            is_verify_email
            salon_owner_status
            receive_sms
            receive_email
            social {
                social_customer_id
                social_id
                username
                type
            }
            seller_id
            meta_title
            meta_keywords
            meta_description
            result_vote {
                id
                vote_id
                vote_name
                url_key
                position
                total_vote
            }
        }
    }
`
const CUSTOMER_QUERYS = {
    GET_CUSTOMER_PROFILE_BY_ID: {
        query: GET_CUSTOMER_PROFILE_BY_ID_QUERY.loc.source.body,
        name: GET_CUSTOMER_PROFILE_BY_ID_QUERY.definitions[0].name.value,
    }
}

export default CUSTOMER_QUERYS