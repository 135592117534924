import { useQuery } from "react-query"
import { HOT_TOPIC_SERVICE } from "services"
import { QUERY_KEYS } from "../queryKeys"

// ============================================================
// HOME QUERIES
// ============================================================
export const useGetHotTopic = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_HOT_TOPIC],
        queryFn: HOT_TOPIC_SERVICE.getHotTopic,
        staleTime: 1000 * 60 * 1,
        // refetchOnWindowFocus: true,
        // refetchOnReconnect: true,
    })
}