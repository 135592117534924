import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";
import useApplicationContextReducer from "./useApplicationContextReducer";
import { parseJSON } from "utils";
import { useAuth } from "globalContext";

const useApplicationContext = () => {
    const { applicationContext, dispatchApplicationContext } = useApplicationContextReducer()

    const isMobile = useMediaQuery({ query: '(max-width: 1023px)' });
    const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });

    const updateIsLoggedIn = (v) => {
        dispatchApplicationContext({
            type: 'UPDATE_IS_LOGGED_IN',
            payload: v
        })
    }

    const updateUser = (userDetail) => {
        dispatchApplicationContext({
            type: 'UPDATE_USER',
            payload: userDetail
        })
    }


    const updatePlatForm = (v) => {
        dispatchApplicationContext({
            type: 'UPDATE_PLATFORM',
            payload: {
                tingTing: v
            }
        })
        localStorage.setItem('dont-show-again', v);
    }

    const updateIsFullScreen = (v) => {
        dispatchApplicationContext({
            type: 'UPDATE_IS_FULL_SCREEN',
            payload: v
        })
    }

    useEffect(() => {
        dispatchApplicationContext({
            type: 'UPDATE_DEVICE',
            payload: {
                isDesktop: isDesktop,
                isMobile: isMobile
            }
        })
    }, [isMobile, isDesktop])



    applicationContext.updaters = {
        updatePlatForm: updatePlatForm,
        updateIsFullScreen: updateIsFullScreen,
        updateUser: updateUser,
        updateIsLoggedIn
    }

    return {
        applicationContext,
        dispatchApplicationContext
    }
}
export default useApplicationContext
