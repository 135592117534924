import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'globalContext';
import { pages } from './pages';
import ScrollTop from '../hooks/useScrollTop';
import ScrollOldPosition from 'components/ScrollOldPosition';
import NewLoading from 'new-components/NewLoading';

const Layout = ({ layout: LayoutComponent, isPublic }) => {
    const auth = useAuth();
    if (isPublic === true || !!auth.user)
        return (
            <LayoutComponent>
                <Outlet />
            </LayoutComponent>
        );
    return <Navigate to="/auth/login" />;
};

const Page = ({ title, component: Comp, ...props }) => {
    const auth = useAuth();

    useEffect(() => {
        auth.setTitlePage('titles.' + title || '');
        document.body.style.overflow = '';
    }, [title, auth]);

    if (typeof Comp === 'string') {
        return <Navigate to={Comp} />;
    }
    return <Comp {...props} />;
};

const Pages = () => {
    return (
        <BrowserRouter>
            <ScrollTop />
            <ScrollOldPosition />
            <React.Suspense fallback={<NewLoading isLoading={true} />}>
                <Routes>
                    {pages.map(({ layout, isPublic, child }, index) => (
                        <Route key={index} element={<Layout layout={layout} isPublic={isPublic} />}>
                            {child.map(({ path, title, component, child }, subIndex) => {
                                if (child?.length) {
                                    return (
                                        <Route
                                            exact
                                            key={path + subIndex}
                                            path={path}
                                            element={<Page title={title} component={component} />}
                                        >
                                            {child.map(({ path, title, component }, subIndex) => (
                                                <Route
                                                    exact
                                                    key={path + subIndex}
                                                    path={path}
                                                    element={<Page title={title} component={component} />}
                                                />
                                            ))}
                                        </Route>
                                    );
                                }
                                return (
                                    <Route
                                        exact
                                        key={path + subIndex}
                                        path={path}
                                        element={<Page title={title} component={component} />}
                                    />
                                );
                            })}
                        </Route>
                    ))}
                </Routes>
            </React.Suspense>
        </BrowserRouter>
    );
};

export default Pages;
