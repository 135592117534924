import { useMutation, } from "react-query"
import CUSTOMER_SERVICE from "services/refactor/customer"
// ============================================================
// CUSTOMER QUERIES
// ============================================================
export const useGetCustomerProfileById = () => {
    return useMutation({
        mutationFn: ({ body }) => {
            if (!body) {
                // get for self
                return CUSTOMER_SERVICE.getCustomerProfileById({
                    body: {
                        customerId: ''
                    }
                })
            } else {
                return CUSTOMER_SERVICE.getCustomerProfileById({ body })
            }
        },
        onSuccess: (data) => {
            return data
        }
    })
}